import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { Image } from '@/components/common/image';
import { Heading, HeadingLevel } from '@/components/common/MarkUp';
import { visuallyHiddenStyle } from '@/components/common/utils';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';
import { labelLarge } from '@/styles/typography';

import type { SanityPartnerLogosType } from '@/types/sanity';

export interface PartnerLogosProps extends SanityPartnerLogosType {}

export const PartnerLogos = ({
  hiddenHeading = '',
  isHeadingVisible = false,
  logos,
  backgroundColour,
  blockSpacing,
  paddingAdjustment,
}: PartnerLogosProps) => {
  const backgroundColourToken =
    backgroundColour?.token || '--background-subtle';

  return (
    <Section
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
      backgroundColour={backgroundColourToken}
      className="partner-logos"
    >
      {hiddenHeading && (
        <HeadingLevel>
          <MainHeading isHeadingVisible={isHeadingVisible}>
            {hiddenHeading}
          </MainHeading>
        </HeadingLevel>
      )}
      <Row>
        <Col sizes={{ lg: 10, md: 10 }} start={{ lg: 2, md: 2 }}>
          <LogosContainer>
            {logos.map((logo) => {
              return <Logo key={logo._key} {...logo.logoImage} />;
            })}
          </LogosContainer>
        </Col>
      </Row>
    </Section>
  );
};

const headingVisibility = (isHeadingVisible) =>
  isHeadingVisible
    ? css`
        ${labelLarge}
      `
    : visuallyHiddenStyle;

const MainHeading = styled(Heading)<{ isHeadingVisible: boolean }>`
  ${({ isHeadingVisible }) => headingVisibility(isHeadingVisible)}
  text-align: center;
  color: var(--text-action);
`;

const LogosContainer = styled.div`
  display: block;
  text-align: center;
  text-wrap: balance;
  justify-content: space-around;
  margin-top: var(--spacing-medium);

  ${screen.lg} {
    min-width: 120px;
    justify-content: center;
  }
`;

const Logo = styled(Image)`
  height: 40px;
  width: auto;
  display: inline-block;
  margin: var(--spacing-xx-small) var(--spacing-large);
  opacity: 0.64;

  ${screen.sm} {
    height: 48px;
  }

  ${screen.lg} {
    height: 56px;
  }
`;
